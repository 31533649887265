import React from 'react'

import Layout from '../components/layout'
import Container from '../components/container'
import BottomHero from '../components/bottomHero'

import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from 'react-accessible-accordion'

import 'react-accessible-accordion/dist/fancy-example.css'

import {
  Flex,
  Box,
  Text,
  Image,
  Heading
} from 'rebass'

import causesInfographic from '../images/causes_infographic.png'
import howReversityWorks from '../images/how_reversity_works.png'
import diabetesStatistics from '../images/diabetes_statistics.png'

const FAQPage = () => (
  <Layout>
    <Container>
      {/* Hero */}
      <Flex flexWrap='wrap' py={3}>
        <Box mx='auto'>
          <Text textAlign='center' fontWeight='bold' py={2} fontSize={5}>Frequently Asked Questions</Text>
        </Box>
      </Flex>

      <Accordion>
        <AccordionItem>
          <AccordionItemTitle>
            <Text fontSize={4} fontWeight='bold'>What are the causes of diabetes?</Text>
          </AccordionItemTitle>
          <AccordionItemBody>
            <Flex p={3}>
              <Box mx='auto'>
                <Image width={600} src={causesInfographic} alt='Causes of diabetes' />
              </Box>
            </Flex>

            <Flex flexWrap='wrap' alignItems='baseline' mx={-2}>
              <Box width={[1, 1/3]} p={2}>
                <Heading fontSize={3} py={1}>1. Low-Fat Diets</Heading>
                <Text>
                  Calls to decrease dietary fat and increase carbohydrates such as whole grains have led to an obesity epidemic.
                </Text>
              </Box>
              <Box width={[1, 1/3]} p={2}>
                <Heading py={1} fontSize={3}>2. Farm Subsidies</Heading>
                <Text>
                  Subsidies for corn, wheat, and soy have made unhealthy foods such as High Fructose Corn Syrup very inexpensive.
                </Text>
              </Box>
              <Box width={[1, 1/3]} p={2}>
                <Heading py={1} fontSize={3}>3. Highly Processed Foods</Heading>
                <Text>
                  Processed foods tend to be calorie-dense and carbohydrate-dense, and often contain inflammatory refined fats.
                </Text>
              </Box>
              <Box width={[1, 1/3]} p={2}>
                <Heading fontSize={3} py={1}>4. Sleep</Heading>
                <Text>
                  Decreased sleep results in disordered endocrine and metabolic function, leading to insulin resistance.
                </Text>
              </Box>
              <Box width={[1, 1/3]} p={2}>
                <Heading py={1} fontSize={3}>5. Chronic Stress</Heading>
                <Text>
                  Stress can elevate blood sugars and increase cortisol levels, which may increase insulin resistance over time.
                </Text>
              </Box>
              <Box width={[1, 1/3]} p={2}>
                <Heading py={1} fontSize={3}>6. Prolonged Sitting</Heading>
                <Text>
                  Sitting for extended periods of time each day can double your risk of type 2 diabetes.
                </Text>
              </Box>
              <Box width={[1, 1/3]} p={2}>
                <Heading py={1} fontSize={3}>7. Genetic Mutations</Heading>
                <Text>
                  Several genetic mutations have been linked to a higher risk of diabetes. These mutations interact with environmental risk factors.
                </Text>
              </Box>
              <Box width={[1, 1/3]} p={2}>
                <Heading py={1} fontSize={3}>8. Family History</Heading>
                <Text>
                  A family history of diabetes increases your risk due to genetic factors as well as familial and cultural eating patterns.
                </Text>
              </Box>
            </Flex>
          </AccordionItemBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemTitle>
            <Text fontSize={4} fontWeight='bold'>How many adults does diabetes affect in the U.S.?</Text>
          </AccordionItemTitle>
          <AccordionItemBody>
            <Flex p={3}>
              <Box mx='auto'>
                <Image width={600} src={diabetesStatistics} alt='Diabetes statistics' />
              </Box>
            </Flex>
          </AccordionItemBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemTitle>
            <Text fontSize={4} fontWeight='bold'>How does Reversity work?</Text>
          </AccordionItemTitle>
          <AccordionItemBody>
            <Flex p={3}>
              <Box mx='auto'>
                <Image width={600} src={howReversityWorks} alt='How Reversity works' />
              </Box>
            </Flex>

            <Flex flexWrap='wrap' alignItems='baseline' mx={-2}>
              <Box width={[1, 1/2]} p={2}>
                <Heading fontSize={3} py={1}>1. Deep Dive</Heading>
                <Text>
                  Deep Dive to build an in-depth picture of your current health status, typical diet, medications, and goals for weight loss. Dr. Harris will use this information to develop a set of specific nutritional changes to stabilize your blood sugars, reverse your diabetes without medications, and meet your personal goals for weight loss.
                </Text>
              </Box>
              <Box width={[1, 1/2]} p={2}>
                <Heading py={1} fontSize={3}>2. Action Plans</Heading>
                <Text>
                  Complete weekly personal Action Plans, guided by Dr. Harris and our team of experts. We will translate your specific nutrition recommendations into a series of concrete steps that you can take to safely regain insulin sensitivity and lose weight. Transform your diet with delicious low-carb, high-fat foods that result in weight loss <Text style={{fontStyle: 'italic', display: 'inline-block'}}>without</Text> counting calories.
                </Text>
              </Box>
              <Box width={[1, 1/2]} p={2}>
                <Heading py={1} fontSize={3}>3. Stabilize Blood Sugars</Heading>
                <Text>
                  Stabilize blood sugars and adjust medications in consultation with your primary care providers. By reducing the amount of carbohydrate in your diet, you will most likely minimize or eliminate your need for diabetes medications and insulin. It is important to monitor your blood sugar closely and work with your existing diabetes care providers to safely manage this transition.
                </Text>
              </Box>
              <Box width={[1, 1/2]} p={2}>
                <Heading fontSize={3} py={1}>4. Achieve Your Goals</Heading>
                <Text>
                  Achieve your health goals and leave the program with the skills to maintain your success. Our goal is to reshape your diet in a satisfying and sustainable way. This means finding a new way of eating that is comprised of foods that you enjoy and can easily incorporate into your existing food budget and daily routines. We want to make sure that you stay diabetes-free for life!
                </Text>
              </Box>
            </Flex>
          </AccordionItemBody>
        </AccordionItem>
      </Accordion>
    </Container>
    <BottomHero/>
  </Layout>
)

export default FAQPage
